import { chakra, Flex,PropsOf, useColorModeValue } from '@fluidtruck/core'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { forwardRef, Ref, useEffect, useRef } from 'react'

const StyledLink = forwardRef(function StyledLink(
  props: PropsOf<typeof chakra.a> & { isActive?: boolean; isExternal?: boolean },
  ref: Ref<any>,
) {
  const { isActive, isExternal = false, ...rest } = props

  return (
    <chakra.a
      _activeLink={{
        bg: useColorModeValue('teal.50', 'rgba(48, 140, 122, 0.3)'),
        color: 'accent-emphasis',
        fontWeight: '600',
      }}
      aria-current={isActive ? 'page' : undefined}
      color="fg"
      fontSize="sm"
      fontWeight="500"
      px="3"
      py="1"
      ref={ref}
      rounded="md"
      target={isExternal ? '_blank' : undefined}
      transition="all 0.2s"
      width="100%"
      {...rest}
    />
  )
})

type SidebarLinkProps = PropsOf<typeof chakra.div> & {
  href?: string;
  icon?: React.ReactElement;
  isExternal?: boolean;
}

function checkHref(href: string, slug: string | string[]) {
  const _slug = Array.isArray(slug) ? slug : [slug]
  const path = href.split('/')
  const pathSlug = path[path.length - 1]
  return _slug.includes(pathSlug)
}

const SidebarLink = ({ href, children, isExternal = false, ...rest }: SidebarLinkProps) => {
  const router = useRouter()
  const isActive = checkHref(href, router.query.slug) || href === router.asPath

  const link = useRef<HTMLAnchorElement>()

  useEffect(() => {
    if (isActive && router.query.scroll === 'true') {
      link.current.scrollIntoView({ block: 'center' })
    }
  }, [isActive, router.query])

  return (
    <Flex align="center" lineHeight="tall" userSelect="none" {...rest}>
      <NextLink href={href} passHref>
        <StyledLink isActive={isActive} isExternal={isExternal} ref={link}>
          {children}
        </StyledLink>
      </NextLink>
    </Flex>
  )
}

export default SidebarLink
