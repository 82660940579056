import { Box, BoxProps } from '@fluidtruck/core'

function CodeContainer(props: BoxProps) {
  return (
    <Box
      bg="#011627"
      my="8"
      rounded="8px"
      sx={{ '& > div': { paddingBlock: '5', paddingEnd: '4' } }}
      {...props}
    />
  )
}

export default CodeContainer
