import { chakra,HTMLChakraProps } from '@fluidtruck/core'
import React from 'react'

export const LinkedHeading = (props: HTMLChakraProps<'h2'>) => (
  <chakra.h2 css={{ scrollMarginBlock: '6.875rem' }} data-group="" {...props}>
    <span className="content">{props.children}</span>
    {props.id && (
      <chakra.a
        _focus={{ opacity: 1, boxShadow: 'outline' }}
        _groupHover={{ opacity: 1 }}
        aria-label="anchor"
        color="teal.500"
        fontWeight="normal"
        href={`#${props.id}`}
        ml="0.375rem"
        opacity={0}
        outline="none"
      >
        #
      </chakra.a>
    )}
  </chakra.h2>
)
