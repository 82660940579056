import { Link, SimpleGrid, Text } from '@fluidtruck/core'
import { ChevronLeftIcon, ChevronRightIcon } from '@fluidtruck/icons'
import NextLink from 'next/link'
import React from 'react'

export const PaginationLink = (props) => {
  const { label, href, children, ...rest } = props

  return (
    <NextLink href={href} passHref>
      <Link
        _hover={{
          textDecor: 'none',
        }}
        borderRadius="md"
        flex="1"
        {...rest}
      >
        <Text fontSize="sm" px="2">
          {label}
        </Text>
        <Text color="teal.400" fontSize="lg" fontWeight="bold" mt="1">
          {children}
        </Text>
      </Link>
    </NextLink>
  )
}

export const Pagination = ({ previous, next, ...rest }) => {
  return (
    <SimpleGrid
      aria-label="Pagination"
      as="nav"
      columns={2}
      my="64px"
      spacing="40px"
      {...rest}
    >
      {previous ? (
        <PaginationLink
          href={previous.path}
          label="Previous"
          rel="prev"
          textAlign="left"
        >
          <ChevronLeftIcon fontSize="1.2em" mr="1" />
          {previous.title}
        </PaginationLink>
      ) : (
        <div />
      )}
      {next ? (
        <PaginationLink
          href={next.path}
          label="Next"
          rel="next"
          textAlign="right"
        >
          {next.title}
          <ChevronRightIcon fontSize="1.2em" ml="1" />
        </PaginationLink>
      ) : (
        <div />
      )}
    </SimpleGrid>
  )
}

export default Pagination
