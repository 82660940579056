import { useSandpack } from '@codesandbox/sandpack-react'
import { Button, Icon } from '@fluidtruck/core'
import { InlineCode } from 'components/mdx-components/inline-code'
import {
  RiFileAddLine,
  RiFileEditLine,
  RiFileReduceLine,
  RiFileTransferLine,
} from 'react-icons/ri'

import { formatFilePath } from './utils'

interface TutorialFileActionProps {
  code?: string;
  label?: string;
  path: string;
  type: 'open' | 'add' | 'delete' | 'update';
}

export const TutorialFileAction = ({
  type,
  path,
  label,
  code = '',
}: TutorialFileActionProps) => {
  const { sandpack } = useSandpack()

  const icons = {
    open: RiFileTransferLine,
    add: RiFileAddLine,
    delete: RiFileReduceLine,
    update: RiFileEditLine,
  }

  return (
    <Button
      as={InlineCode}
      cursor="pointer"
      fontSize="sm"
      leftIcon={<Icon as={icons[type]} />}
      onClick={() => {
        switch (type) {
        case 'open':
          sandpack.openFile(path)
          break
        case 'add':
        case 'update':
          sandpack.updateFile(path, code)
          sandpack.openFile(path)
          break
        case 'delete':
          sandpack.closeFile(path)
          sandpack.deleteFile(path)
          break
        default:
          // eslint-disable-next-line no-console
          console.error('Please select a valid type.')
          break
        }
      }}
      size="xs"
    >
      {label || formatFilePath(path)}
    </Button>
  )
}
