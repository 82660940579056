import { Box, Link as ChakraLink, SimpleGrid, Text } from '@fluidtruck/core'
import Link from 'next/link'

import {
  BlitzSvg,
  CreateReactAppSvg,
  GatsbySvg,
  MeteorSvg,
  NextjsSvg,
  RedwoodSvg,
  RemixSvg,
  ViteSvg,
} from './framework-svg'

const FrameworkLink = (props) => {
  const { accentColor, href, children, name } = props
  return (
    <Link href={href} passHref>
      <ChakraLink
        _hover={{ textDecoration: 'none', translateY: '-2px', shadow: 'md' }}
        bg="white"
        borderRadius="xl"
        display="block"
        overflow="hidden"
        shadow="md"
        textDecoration="none"
        transform="auto"
        transition="all 0.1s ease-in-out"
      >
        <Box pt="4">
          {children}
          <Box bg={accentColor} color="white" mt="4" py="1">
            <Text fontSize="sm" fontWeight="bold" textAlign="center">
              {name}
            </Text>
          </Box>
        </Box>
      </ChakraLink>
    </Link>
  )
}

export const FrameworkLinks = () => {
  return (
    <SimpleGrid fontSize="6xl" minChildWidth="160px" mt="12" spacing="40px">
      <FrameworkLink
        accentColor="#0AC09D"
        href="/getting-started/cra-guide"
        name="Create React App"
      >
        <CreateReactAppSvg style={{ margin: 'auto' }} />
      </FrameworkLink>

      <FrameworkLink
        accentColor="black"
        href="/getting-started/nextjs-guide"
        name="Next.js"
      >
        <NextjsSvg style={{ margin: 'auto' }} />
      </FrameworkLink>

      <FrameworkLink
        accentColor="#663399"
        href="/getting-started/gatsby-guide"
        name="Gatsby"
      >
        <GatsbySvg style={{ margin: 'auto' }} />
      </FrameworkLink>

      <FrameworkLink
        accentColor="#6700EB"
        href="/getting-started/blitzjs-guide"
        name="Blitz.js"
      >
        <BlitzSvg style={{ margin: 'auto' }} />
      </FrameworkLink>

      <FrameworkLink
        accentColor="#BF4722"
        href="/getting-started/redwoodjs-guide"
        name="RedwoodJS"
      >
        <RedwoodSvg style={{ margin: 'auto' }} />
      </FrameworkLink>

      <FrameworkLink
        accentColor="#121212"
        href="/getting-started/remix-guide"
        name="Remix"
      >
        <RemixSvg style={{ margin: 'auto' }} />
      </FrameworkLink>

      <FrameworkLink
        accentColor="#C07600"
        href="/getting-started/vite-guide"
        name="Vite"
      >
        <ViteSvg style={{ margin: 'auto' }} />
      </FrameworkLink>

      <FrameworkLink
        accentColor="#FF6A3E"
        href="/getting-started/meteor-guide"
        name="Meteor"
      >
        <MeteorSvg style={{ margin: 'auto' }} />
      </FrameworkLink>
    </SimpleGrid>
  )
}
