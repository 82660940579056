import { Box, BoxProps, Text } from '@fluidtruck/core'

export default function TocNav({ children, title, ...rest }: BoxProps) {
  return (
    <Box
      alignSelf="start"
      aria-labelledby="toc-title"
      as="nav"
      display={{ base: 'none', xl: 'block' }}
      flexShrink={0}
      fontSize="sm"
      maxHeight="calc(100vh - 8rem)"
      overflowY="auto"
      position="sticky"
      pr="4"
      py="10"
      right="0"
      sx={{ overscrollBehavior: 'contain' }}
      top="6rem"
      width="16rem"
      {...rest}
    >
      {title && (
        <Text
          _dark={{ color: 'gray.400' }}
          as="h2"
          color="gray.700"
          fontSize="xs"
          fontWeight="bold"
          id="toc-title"
          letterSpacing="wide"
          textTransform="uppercase"
        >
          {title}
        </Text>
      )}
      {children}
    </Box>
  )
}
