import {
  Button,
  ButtonProps,
  Link,
  useColorModeValue,
  Wrap,
} from '@fluidtruck/core'
import React from 'react'
import { FaGithub, FaNpm } from 'react-icons/fa'

type ComponentLinkProps = ButtonProps & {
  icon: React.ElementType;
  iconColor?: string;
  iconSize?: string;
  url: string;
}

function ComponentLink(props: ComponentLinkProps) {
  const { icon: BtnIcon, url, children, iconSize, iconColor, ...rest } = props
  return (
    <Button
      _hover={{
        color: useColorModeValue('gray.700', 'whiteAlpha.900'),
        boxShadow: 'sm',
        transform: 'translateY(-2px)',
        textDecor: 'none',
      }}
      as={Link}
      color={useColorModeValue('gray.600', 'whiteAlpha.700')}
      fontWeight="normal"
      href={url}
      isExternal
      leftIcon={<BtnIcon />}
      size="sm"
      sx={{
        '& span': {
          width: iconSize,
        },
        '& svg': {
          color: iconColor,
          width: 'full',
          height: 'auto',
        },
      }}
      variant="outline"
      {...rest}
    >
      {children}
    </Button>
  )
}

export type ComponentLinksProps = {
  github?: { package?: string; url?: string };
  npm?: { package: string };
  storybook?: { url: string };
  theme?: { componentName: string };
  video?: { url: string };
}
function ComponentLinks(props: ComponentLinksProps) {
  const { theme, github, npm, storybook, video, ...rest } = props
  const iconColor = useColorModeValue('gray.600', 'inherit')

  const githubRepoUrl = 'https://github.com/fluidshare/tidal-library'

  const githubLink = (github?.url || github?.package) && (
    <ComponentLink
      icon={FaGithub}
      iconColor={iconColor}
      iconSize="1rem"
      url={
        github.url || `${githubRepoUrl}/tree/main/packages/components/${github.package}`
      }
    >
      View Source
    </ComponentLink>
  )

  const npmLink = npm?.package && (
    <ComponentLink
      icon={FaNpm}
      iconColor="red.500"
      iconSize="2rem"
      url={`https://www.npmjs.com/package/${npm.package}`}
    >
      {npm.package}
    </ComponentLink>
  )

  const themeComponentLink = theme && (
    <ComponentLink
      icon={FaGithub}
      iconColor={iconColor}
      iconSize="1rem"
      url={`${githubRepoUrl}/tree/main/packages/components/theme/src/components/${theme.componentName}.ts`}
    >
      View Theme Source
    </ComponentLink>
  )

  return (
    <Wrap flexWrap="wrap" overflow="visible" spacing="3" {...rest}>
      {githubLink}
      {themeComponentLink}
      {npmLink}
    </Wrap>
  )
}

export default ComponentLinks
