import {
  SandpackCodeViewer,
  SandpackThemeProvider,
  useSandpack,
  useSandpackTheme,
} from '@codesandbox/sandpack-react'
import { nightOwl } from '@codesandbox/sandpack-themes'
import {
  Button,
  ButtonGroup,
  HStack,
  Icon,
  Stack,
  Text,
} from '@fluidtruck/core'
import * as React from 'react'
import { RiFileEditLine, RiFileTransferLine } from 'react-icons/ri'

import { formatFilePath, SandpackLanguageSupport } from './utils'

interface CodeBlockProps {
  code: string;
  language: SandpackLanguageSupport;
  path: string;
  showLineNumbers?: boolean;
}

const CodeBlock = ({ path, code, showLineNumbers = false }: CodeBlockProps) => {
  const { theme } = useSandpackTheme()
  const { sandpack } = useSandpack()

  return (
    <Stack bg={theme.colors.surface1} my={4} rounded="md">
      {path && (
        <HStack justifyContent="space-between" pt={2} px={4}>
          <Text color="purple.300">{formatFilePath(path)}</Text>
          <ButtonGroup>
            <Button
              colorScheme="teal"
              leftIcon={<Icon as={RiFileEditLine} />}
              onClick={() => {
                sandpack.openFile(path)
                sandpack.updateFile(path, code)
              }}
              size="xs"
              textTransform="uppercase"
            >
              Copy to Sandbox
            </Button>
            <Button
              colorScheme="teal"
              leftIcon={<Icon as={RiFileTransferLine} />}
              onClick={() => {
                sandpack.openFile(path)
              }}
              size="xs"
              textTransform="uppercase"
            >
              Open File
            </Button>
          </ButtonGroup>
        </HStack>
      )}
      <SandpackCodeViewer
        code={code}
        showLineNumbers={showLineNumbers}
        showTabs={false}
      />
    </Stack>
  )
}

export const TutorialCodeBlock = (props: CodeBlockProps) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - sandpack has not yet fully provided React 18 support
    <SandpackThemeProvider theme={nightOwl}>
      <CodeBlock {...props} />
    </SandpackThemeProvider>
  )
}
