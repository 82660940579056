import {
  Box,
  chakra,
  Flex,
  HStack,
  HTMLChakraProps,
  Icon,
  IconButton,
  Link,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useUpdateEffect,
} from '@fluidtruck/core'
// import Search from './omni-search'
import { GithubIcon } from 'components/icons'
import siteConfig from 'configs/site-config.json'
import { useScroll } from 'framer-motion'
import NextLink from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { FaMoon, FaSun } from 'react-icons/fa'

import { Logo, LogoIcon } from './logo'
import { MobileNavButton, MobileNavContent } from './mobile-nav'

function HeaderContent() {
  const mobileNav = useDisclosure()

  const { toggleColorMode: toggleMode } = useColorMode()

  const text = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)
  const mobileNavBtnRef = useRef<HTMLButtonElement>()

  useUpdateEffect(() => {
    mobileNavBtnRef.current?.focus()
  }, [mobileNav.isOpen])

  return (
    <>
      <Flex align="center" h="100%" justify="space-between" px="6" w="100%">
        <Flex align="center">
          <NextLink href="/" passHref>
            <chakra.a aria-label="Chakra UI, Back to homepage" display="block">
              <Logo display={{ base: 'none', md: 'block' }} />
              <Box display={{ base: 'block', md: 'none' }} maxW="3rem">
                <LogoIcon />
              </Box>
            </chakra.a>
          </NextLink>
        </Flex>

        <Flex
          align="center"
          color="gray.400"
          justify="flex-end"
          maxW="1100px"
          w="100%"
        >
          {/* <Search /> */}
          <HStack display={{ base: 'none', md: 'flex' }} spacing="5">
            <Link
              aria-label="Go to Chakra UI GitHub page"
              href={siteConfig.repo.url}
              isExternal
            >
              <Icon
                _hover={{ color: 'gray.600' }}
                as={GithubIcon}
                display="block"
                h="5"
                transition="color 0.2s"
                w="5"
              />
            </Link>
          </HStack>
          <HStack spacing="5">
            <IconButton
              aria-label={`Switch to ${text} mode`}
              color="current"
              fontSize="lg"
              icon={<SwitchIcon />}
              ml={{ base: '0', md: '3' }}
              onClick={toggleMode}
              size="md"
              variant="ghost"
            />

            <MobileNavButton
              aria-label="Open Menu"
              onClick={mobileNav.onOpen}
              ref={mobileNavBtnRef}
            />
          </HStack>
        </Flex>
      </Flex>
      <MobileNavContent isOpen={mobileNav.isOpen} onClose={mobileNav.onClose} />
    </>
  )
}

function Header(props: HTMLChakraProps<'header'>) {
  const { maxW = '8xl', maxWidth = '8xl' } = props
  const ref = useRef<HTMLHeadingElement>()
  const [y, setY] = useState(0)
  const { height = 0 } = ref.current?.getBoundingClientRect() ?? {}

  const { scrollY } = useScroll()
  useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()))
  }, [scrollY])

  return (
    <chakra.header
      _dark={{ bg: 'gray.800' }}
      bg="white"
      borderBottom="1px"
      borderColor="gray.200"
      left="0"
      pos="sticky"
      ref={ref}
      right="0"
      shadow={y > height ? 'sm' : undefined}
      top="0"
      transition="box-shadow 0.2s, background-color 0.2s"
      width="full"
      zIndex="11"
      {...props}
    >
      <chakra.div height="4.5rem" maxW={maxW} maxWidth={maxWidth} mx="auto">
        <HeaderContent />
      </chakra.div>
    </chakra.header>
  )
}

export default Header
