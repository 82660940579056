export interface RouteItem {
  external?: true;
  heading?: boolean;
  new?: true;
  open?: boolean;
  path?: string;
  routes?: RouteItem[];
  sort?: boolean;
  summarize?: boolean;
  title: string;
}

export interface Routes {
  routes: RouteItem[];
}

export interface Page {
  html: string;
  id: string;
  title: string;
  toc: any;
}

export interface Post {
  author: string;
  content: string;
  coverImage: string;
  date: string;
  ogImage: {
    url: string;
  };
  slug: string;
  title: string;
  toc: any;
}

export interface RouteContext {
  nextRoute?: RouteItem;
  parent?: RouteItem;
  prevRoute?: RouteItem;
  route?: RouteItem;
}

export const getAllRoutes = (routes: any) => {
  const allRoutes = []

  routes[0].routes.forEach((route: RouteItem) => {
    if (route.routes) {
      route.routes.forEach((item) => {
        allRoutes.push(item)
      })
    } else {
      allRoutes.push(route)
    }
  })

  return allRoutes
}
/**
 * Returns the siblings of a specific route (that is the previous and next routes).
 */
export const getRouteContext = (
  _route: RouteItem,
  routes: RouteItem[],
): RouteContext => {
  let ctx = {}
  if (!_route) return ctx

  const { path } = _route
  const allRoutes = getAllRoutes(routes)

  for (let i = 0; i < allRoutes.length; i += 1) {
    const route = allRoutes[i]

    if (route && route.path === path) {
      const nextRoute = allRoutes[i + 1]
      const prevRoute = allRoutes[i - 1]

      ctx = {
        nextRoute,
        prevRoute,
        route: _route,
      }
    }
  }

  return ctx
}
