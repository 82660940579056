import { BoxProps, chakra, Stack } from '@fluidtruck/core'
import { ReactNode, RefObject, useEffect, useRef, useState } from 'react'

interface SidebarCategoryProps extends BoxProps {
  children: ReactNode;
  contentRef?: RefObject<any>;
  isMobile?: boolean;
  opened?: boolean;
  selected?: boolean;
  title: string;
}

interface SidebarState {
  shouldScroll?: boolean;
  toggle?: boolean;
}

function SidebarCategory(props: SidebarCategoryProps) {
  const { isMobile, title, selected, opened, children, contentRef, ...rest } =
    props

  const ref = useRef<HTMLDivElement | null>(null)

  const [{ toggle, shouldScroll = false }, setToggle] = useState<SidebarState>({
    toggle: selected || opened,
  })

  // If a category is selected indirectly, open it. This can happen when using the search input
  useEffect(() => {
    if (selected) {
      setToggle({ toggle: true, shouldScroll: true })
    }
  }, [selected])

  // Navigate to the start of the category when manually opened
  useEffect(() => {
    if (!ref.current || !contentRef?.current) return
    if (toggle && shouldScroll) {
      const contentEl = contentRef.current

      if (toggle == true && contentEl) {
        // 10 is added for better margin
        const height =
          ref.current.offsetTop - (isMobile ? 10 : contentEl.offsetTop)
        contentEl.scrollTop = height
        setToggle({ toggle })
      }
    }
  }, [toggle, shouldScroll, isMobile, contentRef])

  return (
    <chakra.div mt="8" ref={ref} {...rest}>
      <chakra.p
        alignItems="center"
        color="accent"
        display="flex"
        fontSize="xs"
        fontWeight="bold"
        justifyContent="space-between"
        letterSpacing="wider"
        textTransform="uppercase"
        userSelect="none"
        width="full"
      >
        {title}
      </chakra.p>
      <Stack hidden={!toggle} mt="16px" mx="-3" role="group">
        {children}
      </Stack>
    </chakra.div>
  )
}

export default SidebarCategory
