import { SkipNavContent, SkipNavLink } from '@chakra-ui/skip-nav'
import { Badge, Box, chakra,Flex } from '@fluidtruck/core'
import Header from 'components/header'
import SEO from 'components/seo'
import TableOfContent from 'components/table-of-content'
import { useRouter } from 'next/router'
import * as React from 'react'
import { FrontmatterHeading } from 'src/types/frontmatter'
import { convertBackticksToInlineCode } from 'utils/convert-backticks-to-inline-code'

function useHeadingFocusOnRouteChange() {
  const router = useRouter()

  React.useEffect(() => {
    const onRouteChange = () => {
      const [heading] = Array.from(document.getElementsByTagName('h1'))
      heading?.focus()
    }
    router.events.on('routeChangeComplete', onRouteChange)
    return () => {
      router.events.off('routeChangeComplete', onRouteChange)
    }
  }, [router.events])
}

interface PageContainerProps {
  children: React.ReactNode;
  frontmatter: {
    description?: string;
    editUrl?: string;
    headings?: FrontmatterHeading[];
    slug?: string;
    title: string;
    version?: string;
  };
  hideToc?: boolean;
  leftSidebar?: React.ReactElement;
  maxWidth?: string;
  pagination?: React.ReactElement;
  rightSidebar?: React.ReactElement;
}

function PageContainer(props: PageContainerProps) {
  const {
    frontmatter,
    children,
    leftSidebar,
    rightSidebar,
    pagination,
    hideToc,
    maxWidth = '48rem',
  } = props

  useHeadingFocusOnRouteChange()

  if (!frontmatter) return <></>

  const { title, description, version, headings = [] } = frontmatter

  return (
    <>
      <SEO description={description} title={title} />
      <SkipNavLink zIndex={20}>Skip to content</SkipNavLink>
      <Header />
      <Box as="main" className="main-content" maxW="8xl" mx="auto" w="full">
        <Box display={{ md: 'flex' }}>
          {leftSidebar || null}
          <Box flex="1" minW="0">
            <SkipNavContent />
            <Box id="content" minH="76vh" mx="auto" px={5}>
              <Flex>
                <Box
                  flex="auto"
                  minW="0"
                  pt="10"
                  px={{ base: '4', sm: '6', xl: '8' }}
                >
                  <Box maxW={maxWidth}>
                    <chakra.h1 apply="mdx.h1" outline={0} tabIndex={-1}>
                      {convertBackticksToInlineCode(title)}
                    </chakra.h1>
                    {version && (
                      <Badge colorScheme="teal" letterSpacing="wider">
                        v{version}
                      </Badge>
                    )}
                    {children}
                    <Box mt="40px">
                      {pagination || null}
                    </Box>
                  </Box>
                </Box>
                {!hideToc && (
                  <TableOfContent
                    headings={headings}
                    // @ts-ignore
                    visibility={headings.length === 0 ? 'hidden' : 'initial'}
                  />
                )}
                {rightSidebar}
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default PageContainer
