import { AspectRatio, Box } from '@fluidtruck/core'
import ReactPlayer, { ReactPlayerProps } from 'react-player'

export function VideoPlayer(props: ReactPlayerProps) {
  return (
    <Box mt={3}>
      <AspectRatio ratio={16 / 8.84}>
        <ReactPlayer
          height="100%"
          width="100%"
          {...props}
          config={{
            youtube: {
              playerVars: {
                controls: true,
              },
            },
          }}
        />
      </AspectRatio>
    </Box>
  )
}
