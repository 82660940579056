import {
  ChakraReactSelect,
  Flex,
  Grid,
  Text,
} from '@fluidtruck/core'
import * as icons from '@fluidtruck/icons'
import React from 'react'

const iconList = {
  AppleIcon: icons.AppleIcon,
  AppStoreIcon: icons.AppStoreIcon,
  ArrowDownIcon: icons.ArrowDownIcon,
  ArrowLeftIcon: icons.ArrowLeftIcon,
  ArrowRightIcon: icons.ArrowRightIcon,
  ArrowUpIcon: icons.ArrowUpIcon,
  AvatarIcon: icons.AvatarIcon,
  BankIcon: icons.BankIcon,
  BellIcon: icons.BellIcon,
  BookIcon: icons.BookIcon,
  BulletIcon: icons.BulletIcon,
  BusinessIcon: icons.BusinessIcon,
  CalendarIcon: icons.CalendarIcon,
  CardAddIcon: icons.CardAddIcon,
  CardAmexIcon: icons.CardAmexIcon,
  CardDiscoverIcon: icons.CardDiscoverIcon,
  CardGenericIcon: icons.CardGenericIcon,
  CardMastercardIcon: icons.CardMastercardIcon,
  CardPOIcon: icons.CardPOIcon,
  CardVisaIcon: icons.CardVisaIcon,
  CaretDownIcon: icons.CaretDownIcon,
  CaretUpIcon: icons.CaretUpIcon,
  CargoVanIcon: icons.CargoVanIcon,
  CheckIcon: icons.CheckIcon,
  CheckOutlineIcon: icons.CheckOutlineIcon,
  ChevronLeftIcon: icons.ChevronLeftIcon,
  ChevronRightIcon: icons.ChevronRightIcon,
  ChevronUpIcon: icons.ChevronUpIcon,
  ChevronDownIcon: icons.ChevronDownIcon,
  CircleCheckIcon: icons.CircleCheckIcon,
  CircleArrowIcon: icons.CircleArrowIcon,
  CircleFalseIcon: icons.CircleFalseIcon,
  CircleIcon: icons.CircleIcon,
  ClaimsIcon: icons.ClaimsIcon,
  ClockIcon: icons.ClockIcon,
  CloseIcon: icons.CloseIcon,
  CompanyIcon: icons.CompanyIcon,
  ConstructionCones: icons.ConstructionCones,
  CreditCardIcon: icons.CreditCardIcon,
  CurrentLocationIcon: icons.CurrentLocationIcon,
  DeliveryBoxIcon: icons.DeliveryBoxIcon,
  DocumentIcon: icons.DocumentIcon,
  DocumentsIcon: icons.DocumentsIcon,
  DownloadIcon: icons.DownloadIcon,
  DownVehicleIcon: icons.DownVehicleIcon,
  DriverCupIcon: icons.DriverCupIcon,
  DriverMgmtIcon: icons.DriverMgmtIcon,
  EditIcon: icons.EditIcon,
  EllipsisIcon: icons.EllipsisIcon,
  EnvelopeIcon: icons.EnvelopeIcon,
  EVBadgeIcon: icons.EVBadgeIcon,
  ExclamationCircleIcon: icons.ExclamationCircleIcon,
  ExclamationIcon: icons.ExclamationIcon,
  ExclamationOutlineIcon: icons.ExclamationOutlineIcon,
  ExclamationTriangleIcon: icons.ExclamationTriangleIcon,
  EyeIcon: icons.EyeIcon,
  EyeSlashIcon: icons.EyeSlashIcon,
  FacebookIcon: icons.FacebookIcon,
  FilterIcon: icons.FilterIcon,
  FinishRouteIcon: icons.FinishRouteIcon,
  FlatTireIcon: icons.FlatTireIcon,
  FleetIcon: icons.FleetIcon,
  GasPumpIcon: icons.GasPumpIcon,
  GeofenceIcon: icons.GeofenceIcon,
  GlobeIcon: icons.GlobeIcon,
  GoogleIcon: icons.GoogleIcon,
  GoogleStoreIcon: icons.GoogleStoreIcon,
  HeartEmptyIcon: icons.HeartEmptyIcon,
  HeartFullIcon: icons.HeartFullIcon,
  HelpIcon: icons.HelpIcon,
  HomeIcon: icons.HomeIcon,
  InfoIcon: icons.InfoIcon,
  InstagramIcon: icons.InstagramIcon,
  InstantBookIcon: icons.InstantBookIcon,
  LayersIcon: icons.LayersIcon,
  LeaderCupIcon: icons.LeaderCupIcon,
  LicenseBackIcon: icons.LicenseBackIcon,
  LicenseFrontIcon: icons.LicenseFrontIcon,
  LicenseSelfieIcon: icons.LicenseSelfieIcon,
  LinkedInIcon: icons.LinkedInIcon,
  ListIcon: icons.ListIcon,
  LocationIcon: icons.LocationIcon,
  LockIcon: icons.LockIcon,
  MaintenanceIcon: icons.MaintenanceIcon,
  MapIcon: icons.MapIcon,
  MapPinCheckIcon: icons.MapPinCheckIcon,
  MapPinEVIcon: icons.MapPinEVIcon,
  MapPinIcon: icons.MapPinIcon,
  MaximizeIcon: icons.MaximizeIcon,
  MenuIcon: icons.MenuIcon,
  MessageIcon: icons.MessageIcon,
  MinimizeIcon: icons.MinimizeIcon,
  MinusCircleIcon: icons.MinusCircleIcon,
  PaymentIcon: icons.PaymentIcon,
  PencilIcon: icons.PencilIcon,
  PerformanceIcon: icons.PerformanceIcon,
  PhoneIcon: icons.PhoneIcon,
  PlusCircleIcon: icons.PlusCircleIcon,
  PlusIcon: icons.PlusIcon,
  ReceiptIcon: icons.ReceiptIcon,
  RingIcon: icons.RingIcon,
  RoutingTruckCircle: icons.RoutingTruckCircle,
  SaveIcon: icons.SaveIcon,
  SearchIcon: icons.SearchIcon,
  SettingsIcon: icons.SettingsIcon,
  ShareArrowIcon: icons.ShareArrowIcon,
  ShareIcon: icons.ShareIcon,
  SignOutIcon: icons.SignOutIcon,
  StarIcon: icons.StarIcon,
  StartRouteIcon: icons.StartRouteIcon,
  SteeringWheelIcon: icons.SteeringWheelIcon,
  TargetIcon: icons.TargetIcon,
  TeamAddIcon: icons.TeamAddIcon,
  TeamIcon: icons.TeamIcon,
  TrafficIcon: icons.TrafficIcon,
  TwitterIcon: icons.TwitterIcon,
  UnlockIcon: icons.UnlockIcon,
  UploadIcon: icons.UploadIcon,
  UserIcon: icons.UserIcon,
}

const IconsList = () => {
  const [state, setState] = React.useState(null)
  const options = Object.keys(iconList).map((key) => {
    return { label: key, value: key }
  })
  const SearchedIcon = iconList[state]
  return (
    <>
      <ChakraReactSelect
        isClearable
        onChange={(option: any) => setState(option.value)}
        options={options}
        placeholder="Search Icons..."
        value={state}
      />
      <Grid
        gap={5}
        mt={7}
        templateColumns="repeat( auto-fit, minmax(200px, 1fr) )"
      >   
        {state && 
          (
            <Flex
              align="center"
              backgroundColor="gray.100"
              borderRadius="base"
              borderWidth="1px"
              flexDir="column"
              justify="center"
              p={3}
            >
              <SearchedIcon color="blue.500" h={8} w={8} />
              <Text fontSize="sm" mt={2} textAlign="center">
                {`${state}`}
              </Text>
            </Flex>
          )
        }

        {!state && Object.keys(iconList).map((key, i) => {
          const Icon = iconList[key]

          return (
            <Flex
              align="center"
              backgroundColor="gray.100"
              borderRadius="base"
              borderWidth="1px"
              flexDir="column"
              justify="center"
              key={i}
              p={3}
            >
              <Icon color="blue.500" h={8} w={8} />
              <Text fontSize="sm" mt={2} textAlign="center">
                {`${key}`}
              </Text>
            </Flex>
          )
        })}
      </Grid>
    </>
  )
}

export default IconsList
