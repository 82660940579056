import {
  Badge,
  Box,
  Center,
  chakra,
  HStack,
  List,
  ListItem,
  ListProps,
} from '@fluidtruck/core'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, ReactElement, ReactNode, useRef } from 'react'
import { AiFillPlayCircle } from 'react-icons/ai'
import { BsFillGridFill } from 'react-icons/bs'
import { FaCompass, FaGlobe, FaPalette, FaTools } from 'react-icons/fa'
import { FiFigma } from 'react-icons/fi'
import { convertBackticksToInlineCode } from 'utils/convert-backticks-to-inline-code'
import { RouteItem, Routes } from 'utils/get-route-context'

import SidebarCategory from './sidebar-category'
import SidebarLink from './sidebar-link'

const sortRoutes = (routes: RouteItem[]) => {
  return routes.sort(({ title: titleA }, { title: titleB }) => {
    if (titleA < titleB) return -1
    if (titleA > titleB) return 1
    return 0
  })
}

export type SidebarContentProps = Routes & {
  contentRef?: any;
  pathname?: string;
}

function NewBadge() {
  return (
    <Badge
      colorScheme="purple"
      fontSize="10px"
      lineHeight="tall"
      ml="2"
      variant="solid"
    >
      New
    </Badge>
  )
}

export function SidebarContent({
  routes,
  pathname,
  contentRef,
}: SidebarContentProps) {
  return (
    <>
      {routes.map((lvl1, idx) => {
        return (
          <Fragment key={idx}>
            {lvl1.heading && (
              <chakra.h4
                fontSize="sm"
                fontWeight="bold"
                letterSpacing="wider"
                my="4"
                textTransform="uppercase"
              >
                {lvl1.title}
              </chakra.h4>
            )}

            {lvl1.routes.map((lvl2, index) => {
              if (!lvl2.routes) {
                return (
                  <SidebarLink
                    href={lvl2.path}
                    isExternal={lvl2.external}
                    key={lvl2.path}
                    ml="-3"
                    mt="2"
                  >
                    {lvl2.title}
                  </SidebarLink>
                )
              }

              const selected = pathname.startsWith(lvl2.path)
              const opened = selected || lvl2.open

              const sortedRoutes = lvl2.sort
                ? sortRoutes(lvl2.routes)
                : lvl2.routes

              return (
                <SidebarCategory
                  contentRef={contentRef}
                  key={lvl2.path + index}
                  opened={opened}
                  selected={selected}
                  title={lvl2.title}
                >
                  {sortedRoutes.map((lvl3) => (
                    <SidebarLink href={lvl3.path} key={lvl3.path}>
                      <span>{convertBackticksToInlineCode(lvl3.title)}</span>
                      {lvl3.new && <NewBadge />}
                    </SidebarLink>
                  ))}
                </SidebarCategory>
              )
            })}
          </Fragment>
        )
      })}
    </>
  )
}

type MainNavLinkProps = {
  children: ReactNode;
  href: string;
  icon: ReactElement;
  isActive?: boolean;
  isExternal?: boolean;
  label?: string;
}

const MainNavLink = ({
  href,
  icon,
  children,
  isActive,
  isExternal,
}: MainNavLinkProps) => {
  const router = useRouter()
  const active = router.asPath.startsWith(href) || !!isActive

  return (
    <NextLink href={href} passHref>
      <HStack
        _hover={{ color: active ? undefined : 'fg' }}
        as="a"
        color={active ? 'accent' : 'fg-muted'}
        fontSize="sm"
        fontWeight={active ? 'semibold' : 'medium'}
        spacing="3"
        target={isExternal ? '_blank' : undefined}
      >
        <Center
          bg={active ? 'accent-static' : 'transparent'}
          borderColor={active ? 'accent-static' : undefined}
          borderWidth="1px"
          color={active ? 'white' : 'accent'}
          h="6"
          rounded="base"
          w="6"
        >
          {icon}
        </Center>
        <span>{children}</span>
      </HStack>
    </NextLink>
  )
}

export const mainNavLinks = [
  {
    icon: <FaCompass />,
    href: '/getting-started',
    label: 'Getting Started',
  },
  {
    icon: <FaPalette />,
    href: '/docs/styled-system/style-props',
    label: 'Styled System',
    match: (asPath: string, href: string) =>
      href.startsWith('/docs/styled-system') &&
      asPath.startsWith('/docs/styled-system'),
  },
  {
    icon: <BsFillGridFill />,
    href: '/docs/components',
    label: 'Components',
  },
  {
    icon: <FaTools />,
    href: '/docs/hooks/use-boolean',
    label: 'Hooks',
    match: (asPath: string, href: string) =>
      href.startsWith('/docs/hooks') && asPath.startsWith('/docs/hooks'),
  },
  {
    icon: <FiFigma />,
    href: '/figma/ui-kit',
    label: 'Figma',
    match: (asPath: string, href: string) =>
      href.startsWith('/figma') && asPath.startsWith('/figma'),
  },
  {
    icon: <FaGlobe />,
    href: '/community/team',
    label: 'Community',
    match: (asPath: string, href: string) =>
      href.startsWith('/community') && asPath.startsWith('/community'),
  },
  {
    icon: <AiFillPlayCircle />,
    href: 'https://play.chakra-ui.com',
    label: 'Playground',
    new: false,
    external: true,
  },
  // {
  //   icon: <FaReadme />,
  //   href: '/blog',
  //   label: 'Blog',
  // },
]

export const MainNavLinkGroup = (props: ListProps) => {
  const router = useRouter()

  return (
    <List spacing="4" styleType="none" {...props}>
      {mainNavLinks.map((item) => (
        <ListItem key={item.label}>
          <MainNavLink
            href={item.href}
            icon={item.icon}
            isActive={item.match?.(router.asPath, item.href)}
            isExternal={item.external}
            label={item.label}
          >
            {item.label} {item.new && <NewBadge />}
          </MainNavLink>
        </ListItem>
      ))}
    </List>
  )
}

const Sidebar = ({ routes }) => {
  const { pathname } = useRouter()
  const ref = useRef<HTMLDivElement>(null)

  return (
    <Box
      aria-label="Main Navigation"
      as="nav"
      className="sidebar-content"
      display={{ base: 'none', md: 'block' }}
      flexShrink={0}
      h="calc(100vh - 8.125rem)"
      overflowY="auto"
      overscrollBehavior="contain"
      pb="6"
      pl="6"
      pos="sticky"
      pr="8"
      pt="4"
      ref={ref}
      top="6.5rem"
      w="280px"
    >
      <MainNavLinkGroup mb="10" />
      <SidebarContent contentRef={ref} pathname={pathname} routes={routes} />
    </Box>
  )
}

export default Sidebar
