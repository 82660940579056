import React from 'react'

export const liveEditorStyle: React.CSSProperties = {
  fontSize: 14,
  // overflowX: 'auto',
  fontFamily: 'SF Mono, Menlo, monospace',
}

export const liveErrorStyle: React.CSSProperties = {
  fontFamily: 'SF Mono, Menlo, monospace',
  fontSize: 14,
  padding: '1em',
  // overflowX: 'auto',
  color: 'white',
  backgroundColor: 'red',
}
