import { Flex, Heading, Icon, SimpleGrid, Stack, Text } from '@fluidtruck/core'
import componentsSidebar from 'configs/components.sidebar.json'
import styledSystemSidebar from 'configs/styled-system.sidebar.json'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  RiArchiveFill,
  RiCheckboxMultipleFill,
  RiDashboardFill,
  RiEyeCloseLine,
  RiFeedbackFill,
  RiImage2Fill,
  RiInputMethodLine,
  RiListOrdered,
  RiNavigationFill,
  RiPaletteLine,
  RiPictureInPictureExitFill,
  RiRepeat2Fill,
} from 'react-icons/ri'

const featureSidebar = {
  '/docs/styled-system': styledSystemSidebar,
  '/docs/components': componentsSidebar,
}

const Feature = ({ title, icon, children, ...props }) => {
  return (
    <Stack
      _dark={{ bg: 'gray.700' }}
      bg="white"
      cursor="pointer"
      direction="row"
      p="6"
      rounded="12px"
      shadow="base"
      spacing={4}
      {...props}
    >
      <Flex
        align="center"
        bg="teal.500"
        h="12"
        justify="center"
        rounded="12px"
        w="12"
      >
        <Icon as={icon} color="white" fontSize="24px" />
      </Flex>
      <Flex direction="column">
        <Heading as="h3" fontWeight="semibold" mb="0.5" mt="1" size="sm">
          {title}
        </Heading>
        <Text fontSize="md" opacity={0.7}>
          {children}
        </Text>
      </Flex>
    </Stack>
  )
}

export const FeaturesOverview = () => {
  const { asPath } = useRouter()

  const features = featureSidebar[asPath].routes?.[0].routes.filter(
    (feature) => feature.path !== asPath,
  )

  const changeFeatureText = (path: string) => {
    switch (true) {
    case path.includes('recipes'):
      return 'recipes'
    case path.includes('hooks'):
      return 'hooks'
    default:
      return 'components'
    }
  }

  const icons = {
    Features: RiListOrdered,
    Theming: RiPaletteLine,
    'Utility Hooks': RiRepeat2Fill,
    'Component Hooks': RiRepeat2Fill,
    Forms: RiCheckboxMultipleFill,
    'Data Display': RiDashboardFill,
    Overlay: RiPictureInPictureExitFill,
    Feedback: RiFeedbackFill,
    Typography: RiInputMethodLine,
    Disclosure: RiEyeCloseLine,
    Navigation: RiNavigationFill,
    'Media and icons': RiImage2Fill,
    Other: RiArchiveFill,
  }

  return (
    <SimpleGrid minChildWidth="15.625rem" mt="12" spacing="8">
      {features.map((feature) => (
        <Link
          href={`${feature.routes?.[0].path}?scroll=true`}
          key={feature.title}
          passHref
        >
          <Feature icon={icons[feature.title] ?? null} title={feature.title}>
            {feature.summarize
              ? `${feature.routes.length} ${changeFeatureText(feature.path)}`
              : null}
          </Feature>
        </Link>
      ))}
    </SimpleGrid>
  )
}
