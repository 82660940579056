import * as Tidal from '@fluidtruck/core'
import {
  ColorPalette,
  ColorPalettes,
  ColorWrapper,
} from 'components/color-palette'
import { FeaturesOverview } from 'components/features-overview'
import { FrameworkLinks } from 'components/framework-link'
import { Anchor } from 'components/mdx-components/anchor'
import { InlineCode } from 'components/mdx-components/inline-code'
import { LinkedHeading } from 'components/mdx-components/linked-heading'
import { Pre } from 'components/mdx-components/pre'
import { Table, TData, THead } from 'components/mdx-components/table'
import { PackageManagers } from 'components/package-managers'
// import SandpackEmbed from 'components/sandpack-embed'
import { TutorialCodeBlock } from 'components/tutorial/tutorial-code-block'
import { TutorialFileAction } from 'components/tutorial/tutorial-file-action'
import NextImage from 'next/image'
import React from 'react'

import PropsTable from '../props-table'
import CodeBlock from './codeblock/codeblock'
import ComponentLinks from './component-links'
import IconsList from './icons-list'
import { VideoPlayer } from './video-player'

const { Alert, AspectRatio, Box, chakra, Kbd, Link } = Tidal

export const MDXComponents = {
  ...Tidal,
  Image: ({ ratio, border, src, ...props }: any) => (
    <AspectRatio
      borderWidth={border ? '1px' : undefined}
      my="5"
      position="relative"
      ratio={ratio}
    >
      <NextImage
        alt=""
        layout="fill"
        objectFit="contain"
        src={src}
        {...props}
      />
    </AspectRatio>
  ),
  LinkedImage: ({ href, ...props }) => (
    <Link display="block" href={href} isExternal my="10">
      <MDXComponents.Image {...props} />
    </Link>
  ),
  h1: (props) => <chakra.h1 apply="mdx.h1" {...props} />,
  h2: (props) => <LinkedHeading apply="mdx.h2" {...props} />,
  h3: (props) => <LinkedHeading apply="mdx.h3" as="h3" {...props} />,
  h4: (props) => <LinkedHeading apply="mdx.h4" as="h4" {...props} />,
  hr: (props) => <chakra.hr apply="mdx.hr" {...props} />,
  strong: (props) => <Box as="strong" fontWeight="semibold" {...props} />,
  code: InlineCode,
  pre: (props) => {
    if (typeof props.children === 'string') return <Pre {...props} />
    if (props.children.props.type === 'tutorial') {
      const className = props.children.props.className || ''
      const code = props.children.props.children.trim() || ''
      const language = className.replace(/language-/, '')
      return (
        <TutorialCodeBlock
          code={code}
          language={language}
          path={props.children.props.path}
          showLineNumbers={props.showLineNumbers}
        />
      )
    }
    return <CodeBlock {...props} />
  },
  kbd: Kbd,
  br: ({ reset, ...props }) => (
    <Box
      as={reset ? 'br' : undefined}
      height={reset ? undefined : '24px'}
      {...props}
    />
  ),
  table: Table,
  th: THead,
  td: TData,
  a: Anchor,
  p: (props) => <chakra.p apply="mdx.p" {...props} />,
  ul: (props) => <chakra.ul apply="mdx.ul" {...props} />,
  ol: (props) => <chakra.ol apply="mdx.ul" {...props} />,
  li: (props) => <chakra.li pb="4px" {...props} />,
  blockquote: (props) => (
    <Alert
      as="blockquote"
      mt="4"
      my="1.5rem"
      role="none"
      rounded="4px"
      status="warning"
      variant="left-accent"
      bg="orange.100"
      {...props}
    />
  ),
  ComponentLinks,
  IconsList,
  PropsTable,
  FrameworkLinks,
  VideoPlayer,
  AspectRatio,
  ColorPalette,
  ColorPalettes,
  ColorWrapper,
  SandpackEmbed: () => <div />,
  // TODO: need codesandbox.io pro verison to pass private deps
  // SandpackEmbed: (props) => (
  //   <Box my={6}>
  //     <SandpackEmbed {...props} />
  //   </Box>
  // ),
  FeaturesOverview,
  TutorialFileAction,
  PackageManagers,
}
