import siteConfig from 'configs/site-config.json'
import { NextSeo, NextSeoProps } from 'next-seo'
import React from 'react'

export type SEOProps = Pick<NextSeoProps, 'title' | 'description'>

const SEO = ({ title, description }: SEOProps) => (
  <NextSeo
    description={description}
    openGraph={{ title, description }}
    title={title}
    titleTemplate={siteConfig.seo.titleTemplate}
  />
)

export default SEO
