import { chakra } from '@fluidtruck/core'
import * as React from 'react'

export const Table = (props) => (
  <chakra.div overflowX="auto">
    <chakra.table mt="32px" textAlign="left" width="full" {...props} />
  </chakra.div>
)

export const THead = (props) => (
  <chakra.th
    _dark={{ bg: 'whiteAlpha.100' }}
    bg="gray.50"
    fontSize="sm"
    fontWeight="semibold"
    p={2}
    {...props}
  />
)

export const TData = (props) => (
  <chakra.td
    borderColor="inherit"
    borderTopWidth="1px"
    fontSize="sm"
    p={2}
    whiteSpace="normal"
    {...props}
  />
)
