import {
  Box,
  BoxProps,
  chakra,
  ListItem,
  OrderedList,
  useColorModeValue,
} from '@fluidtruck/core'
import { useScrollSpy } from 'hooks/use-scrollspy'
import type { FrontmatterHeading } from 'src/types/frontmatter'

import TocNav from './toc-nav'

interface TableOfContentProps extends BoxProps {
  headings: FrontmatterHeading[];
}

function TableOfContent(props: TableOfContentProps) {
  const { headings, ...rest } = props
  const activeId = useScrollSpy(
    headings.map(({ id }) => `[id="${id}"]`),
    {
      rootMargin: '0% 0% -24% 0%',
    },
  )
  const linkColor = useColorModeValue('gray.600', 'gray.400')
  const linkHoverColor = useColorModeValue('gray.900', 'gray.600')
  return (
    <TocNav title="On this page" {...rest}>
      <OrderedList ml="0" mt="4" spacing={1} styleType="none">
        {headings.map(({ id, text, level }) => (
          <ListItem key={id} ml={level === 'h3' ? '4' : undefined} title={text}>
            <chakra.a
              _hover={{
                color: linkHoverColor,
              }}
              aria-current={id === activeId ? 'location' : undefined}
              color={linkColor}
              display="block"
              fontWeight={id === activeId ? 'bold' : 'medium'}
              href={`#${id}`}
              py="1"
            >
              {text}
            </chakra.a>
          </ListItem>
        ))}
      </OrderedList>
    </TocNav>
  )
}

export default TableOfContent
